/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

export default function SectionAvis() {
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  return (
    <div className="pt-8 lg:py-16 bg-sky-400">
      <div className="px-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex relative items-center py-5 px-6 space-x-3 bg-white rounded-lg border border-gray-300 shadow-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:border-gray-400">
          <div className="flex-shrink-0">
            <StaticImage
              className="object-cover object-center"
              src="../../images/trust.png"
              alt="Avis Google 1"
              height={100}
            />
          </div>
          <div className="flex-1 min-w-0">
            <span className="absolute inset-0" aria-hidden="true" />
            <p className="text-base font-medium text-gray-900">Fissuration</p>
            <p className="text-sm text-gray-500 truncate">
              97 allée Alexandre Borodine, 69800 St Priest
            </p>
            <p className="text-sm text-gray-500 truncate">
              <ul class="flex mb-0">
                <li className="pr-2 text-base">4,4</li>
                {/* <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li>
                      <li>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                          <path fill="currentColor"
                            d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                        </svg>
                      </li> */}
                <li className="pl-2 text-base">12 avis</li>
              </ul>
            </p>
          </div>
        </div>

        <Slider
          {...settings}
          className="px-4 mt-4 w-full text-white align-middle"
        >
          {/* 
                Etoile vide
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m323 851 157-94 157 95-42-178 138-120-182-16-71-168-71 167-182 16 138 120-42 178Zm-90 125 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Zm247-355Z" />
                  </svg>
                </li> 
                
                Etoile moitier
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m480 757 157 95-42-178 138-120-182-16-71-168v387ZM233 976l65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                  </svg>
                </li>

                Etoile plein
                <li>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 96 960 960" class="w-5 text-warning">
                    <path fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z" />
                  </svg>
                </li>

                */}

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Thierry Debarnot</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>
                  Une aide précieuse pour l'indemnisation des fissures
                </strong>
                <br />
                Après avoir fait ma demande sur le site internet, j'ai pu
                présenter ma problématique et échanger avec un expert bâtiment,
                spécialiste des fissures et indépendant de l'assurance. Le
                service répond parfaitement à mes attentes. L'expert se déplace
                sur place et réalise des préconisations pragmatiques. Les
                conseils sont de qualité sur les causes des fissures, les
                risques et les travaux. On nous oriente aussi pour le volet
                assurance, l'application des garanties et la démarche pour
                obtenir une meilleure indemnisation
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Pura</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>Entreprise fortement recommandée !</strong>
                <br />
                J'ai contacté cette entreprise via le site internet. A peine
                quelques heures après, Monsieur Glasson a pris le temps de
                m'appeler alors qu'il venait de terminer sa journée de travail.
                Il m'a expliqué pendant un certain temps le but de l'entreprise,
                son fonctionnement et comment il pouvait répondre à ma
                problématique. De plus, il m'a également orienté vers des
                professionnels afin d'avancer dans mes démarches. Réactivité,
                efficacité et professionnalisme, c'est ce qui résume mon
                expérience avec cette entreprise qui mérite d'être davantage
                connue !
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Safia</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>Echange complet et très instructif</strong>
                <br />
                Echange complet et très instructif avec Yoann Glasson qui s'est
                montré impliqué et très pédagogue. Nous n'avons pas pu
                poursuivre avec ses partenaires à cause des délais mais je
                recommande vivement son conseil.
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Frankie R.</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                      Graphic Designer
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>Pro des fissures</strong>
                <br />
                Site incontournable dès qu'on parle fissures Explications et
                conseils hyper pragmatiques. Aucune pression pour acheter
                quoique ce soit. Le Cabinet Dubois Expertise qui m'a été
                recommandé a fait un boulot formidable
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Elie</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                    Local Guide · Niveau 3
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>Très bon service avec en prime un super échange</strong>
                <br />
                J'ai découvert ce service en contactant un bureau d'études à
                Paris, qui m'a redirigé vers le site Koudepouce. J'ai pu
                formuler ensuite au travers de ce site internet, une demande de
                mise en relation pour une étude technique autour du
                réaménagement d'un appartement (suppression de cloisons,
                identification du type de mur ,...). La découverte de ce service
                est d'autant plus intéressante, surtout quand votre
                interlocuteur est un passionné, qui prends le temps de vous
                expliquer sa démarche et les différentes étapes pour répondre à
                votre besoin. Merci Monsieur Glasson pour votre aide.
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>

          <div className="flex justify-center items-center p-4">
            <div class="mb-12 md:mb-0">
              <div class="flex justify-center mb-6">
                <StaticImage
                  className="rounded-full shadow-lg dark:shadow-black/20"
                  src="../../images/avatar.png"
                  alt="Avis Google 1"
                />
              </div>
              <h5 class="mb-2 text-lg font-bold">Catherine JOBY</h5>
              {/* <h6 class="mb-4 font-medium text-primary dark:text-primary-400">
                    Local Guide · Niveau 3
                    </h6> */}
              <p class="mb-4 text-sm">
                <strong>Écoute attentive, accueil chaleureux</strong>
                <br />
                Outre un accueil des plus chaleureux ainsi qu'une écoute
                attentive et une rapide compréhension du problème, j'ai obtenu
                des conseils précis sur mon projet (en prévision d'un achat
                d'une maison dont l'extension s'est affaissée de 2cm). Monsieur
                Glasson n'a pas ménagé son temps, il est allé très loin dans les
                explications, me permettant d'entrevoir d'autres solutions que
                celles que j'avais envisagées, y compris pour un coût moindre.
                La mise en relation avec des professionnels est encore en cours,
                mais n'hésitez pas à prendre contact, (ce qui n'engage à rien)
                et je pense que, comme moi, vous serez ravis du résultat.
              </p>
              <ul class="flex justify-center mb-0">
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
                <li>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 96 960 960"
                    class="w-5 text-warning"
                  >
                    <path
                      fill="currentColor"
                      d="m233 976 65-281L80 506l288-25 112-265 112 265 288 25-218 189 65 281-247-149-247 149Z"
                    />
                  </svg>
                </li>
              </ul>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}
